.root {
    width: fit-content;
}

.menu {
    display: flex;
    flex-wrap: wrap;
}

.menuGroup {
    display: flex;
    gap: 16px;
    padding: 8px;
    box-sizing: border-box;
    background: #bfbfbf;
    box-shadow: inset -1px -1px 0px #808080, inset 1px 1px 0px #ffffff;
}

.mainMenu {
    width: 100%;
    flex-grow: 2;
}

.subMenu {
    flex-basis: 40%;
    flex-grow: 1;
}

.toolBarItem {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.subMenuAddress {
    align-items: center;
    flex-basis: 80%;
    flex-grow: 1;
    padding: 5px 8px;
    gap: 8px;
}

.subMenuLinks {
    width: 100px;
}

.urlInput {
    display: flex;
    align-items: center;
    height: 26px;
    flex-grow: 1;
    gap: 8px;
    padding-left: 6px;
    background: #ffffff;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
}

.urlInputIconWrapper {
    width: 16px;
    height: 16px;
}

.urlInputInput {
    flex-grow: 1;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.content {
    padding: 1px;
    margin: 6px;
    background-color: #ffffff;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
    overflow: auto;
}
