@import '../../styles/media.scss';

.root {
    width: 1160px;
    height: 605px;

    @include _1920x1080 {
        height: 800px;
    }
}

.imageWrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin: 3px;
    margin-bottom: 6px;
}

.content {
    display: flex;
    flex-direction: column;
}

.image {
    width: 1144px;
    height: 517px;
    object-fit: cover;

    @include _1920x1080 {
        height: 708px;
    }
}

.wrongPixel {
    position: absolute;
    top: 58px;
    left: 519px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    opacity: 0;

    @include _1920x1080 {
        top: 154px;
        left: 520px;
    }
}
