@font-face {
    font-family: 'BERNIER Distressed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/BERNIER-Distressed-Regular.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Gilroy-Regular.woff');
}

@font-face {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Anton-Regular.ttf');
}

html {
    height: 100%;
    min-width: 1280px;
    min-height: 720px;
}

body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    overflow: hidden;
}
