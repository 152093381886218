@import '../../styles/media.scss';

.root {
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include _1920x1080 {
        width: 64px;
    }
}

.iconWrapper {
    width: 48px;
    height: 48px;

    @include _1920x1080 {
        width: 64px;
        height: 64px;
        margin-bottom: 8px;
    }
}

.label {
    width: 110px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    text-align: center;

    @include _1920x1080 {
        width: 124px;
        font-size: 18px;
        line-height: 21px;
    }
}
