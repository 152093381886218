.speechBalloon {
    top: 355px;
    left: 320px;
}

.text {
    position: absolute;
    bottom: 0px;
    right: 320px;

    width: 80px;
    height: 44px;

    font-family: 'BERNIER Distressed';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 110%;
    color: #000000;
}
