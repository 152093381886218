.root {
    border: none;
    border-radius: 0;
    padding: 8px 16px;

    background: #bfbfbf;
    box-shadow: inset -1px -1px 0px #808080, inset 1px 1px 0px #ffffff;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;

    &:disabled {
        box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #808080;
    }
}
