@import '../../styles/media.scss';

.root {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: black;
    background-image: url('../../assets/images/background2.progressive.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.logo {
    position: absolute;
    top: 35px;
    left: 75px;
    width: 230px;
    height: 35px;
}

.legendContainer {
    position: relative;
    width: fit-content;
    padding: 35px 60px 30px 75px;
    box-sizing: border-box;

    font-family: 'BERNIER Distressed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    color: #ffffff;

    z-index: 1;

    @include _1920x1080 {
        padding: 85px 80px 62px 80px;
        font-size: 40px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(13px);
        border-radius: 15px;
        transform: rotate(3deg);
        z-index: -1;
    }
}

.paragraph {
    margin: 0 0 15px 0;
}

.button {
    display: block;
    width: 400px;
    height: 81px;
    margin: 39px auto 0 auto;
    padding: 16px 50px 25px 50px;

    font-size: 32px;

    background-color: #ef38ff;

    @include _1920x1080 {
        width: 517px;
        height: 127px;
        padding: 31px 50px 52px 50px;
    }

    &:hover {
        background-color: #b271b8;
    }
}

.policeBadge {
    position: absolute;
    top: -75px;
    right: -110px;
    background-image: url('../../../public/img/PoliceBadge.svg');
    width: 256px;
    height: 292px;

    @include _1920x1080 {
        top: -25px;
        right: -80px;
    }
}
