@import '../../../styles/media.scss';

.root {
    position: relative;
    width: 100%;
    height: 100%;
}

.openFolder {
    width: 100%;
    height: 100%;
    background-image: url('../../../../public/img/OpenFolderIcon.png');
    background-position: center;
    background-size: contain;
}

.warning {
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 7px;
    left: -6px;

    @include _1920x1080 {
        width: 24px;
        height: 24px;
        bottom: 0;
    }
}

.error {
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 7px;
    left: -4px;

    @include _1920x1080 {
        width: 24px;
        height: 24px;
        bottom: 0;
        left: -6px;
    }
}
