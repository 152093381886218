.root {
    width: 1144px;
    height: 631px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('../../../public/img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
    color: #ffffff;
    overflow: hidden;
}

.container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: 100%;
    align-self: center;
    margin-left: 40px;
    margin-top: 40px;
}

.title {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    white-space: nowrap;
}

button.button {
    width: 441px;
    height: 87px;
    margin-right: -36px;
    padding: 18px 50px 34px 50px;
    font-size: 32px;
    line-height: 110%;
    background-color: #a0ffdd;
    transition: 0.2s linear;
    color: black;

    &:hover {
        background-color: #8faea3;
    }
}
