.root {
    display: block;
    min-height: 24px;
    min-width: 24px;
    padding: 6px;

    border: none;
    border-radius: 0;
    box-sizing: border-box;

    background: silver;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf;

    &:active {
        box-shadow: inset -1px -1px #fff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px grey;
    }
}

.icon {
    display: block;
    width: 12px;
    height: 12px;
}
