.root {
    position: absolute;
    bottom: 0;
    right: 10%;
    height: 50%;
    min-height: 500px;
    max-height: 650px;
    z-index: 100;
    pointer-events: none;

    img {
        display: block;
    }
}
