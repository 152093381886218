.root {
    margin: 0 auto;
    position: relative;
    width: 1026px;
    padding: 40px;
    display: flex;
    gap: 60px;
    background: #232530;
    border-radius: 40px;
    text-align: left;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/textures/texture0.png');
        background-size: 1506px 1023px;
        background-position: -75px -195px;
        background-blend-mode: darken;
        opacity: 0.3;
    }
}

.content {
    flex-grow: 1;
    z-index: 1;
}

.imageWrapper {
    position: relative;
    border-radius: 40px;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-image: url('../../assets/textures/texture1.jpg');
        background-size: contain;
        background-position: center;
        mix-blend-mode: screen;
    }
}

.image {
    display: block;
    position: relative;
    width: 400px;
    height: 400px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.buttonsContainer {
    display: flex;
    gap: 20px;
}

.buttonArrow {
    position: relative;
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    &:active {
        background: rgba(255, 255, 255, 0.2);
    }
}

.title {
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
}

.description {
    margin-bottom: 42px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
}

.subTitle {
    position: relative;
    width: fit-content;
    margin-bottom: 20px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #ffffff;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -81px;
        left: -16px;
        bottom: -2px;
        border-radius: 30px 0px 0px 30px;
        z-index: -1;

        .pink & {
            background: linear-gradient(90deg, #e037f2 1.33%, rgba(234, 47, 238, 0) 89.09%);
        }

        .orange & {
            background: linear-gradient(90deg, #e66857 1.33%, rgba(238, 114, 83, 0) 89.09%);
        }

        .blue & {
            background: linear-gradient(90deg, #56b8fd 1.33%, rgba(78, 178, 253, 0) 89.09%);
        }
    }
}

.effects {
    max-height: 140px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
}

.effect {
    max-width: 215px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.iconWrapper {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.effectDescription {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #ffffff;
}
