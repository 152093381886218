@import '../../styles/media.scss';

.root {
    min-width: 1144px;
    height: 445px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;

    background-image: url('../../../public/img/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;

    @include _1920x1080 {
        height: 631px;
    }
}

.imageWrapper {
    width: 280px;
    height: 280px;
    border-radius: 20px;
    overflow: hidden;
    transform: rotate(-6.6deg);

    @include _1920x1080 {
        width: 350px;
        height: 350px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/textures/texture3.jpg');
        background-size: cover;
        background-position: center;
        mix-blend-mode: screen;
    }
}

.image {
    width: 100%;
    height: 100%;
}

.wrapper {
    width: 285px;

    @include _1920x1080 {
        width: 430px;
    }
}

.title {
    margin-bottom: 40px;
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 57px;
    line-height: 110%;
    text-transform: uppercase;
    color: #ffffff;

    @include _1920x1080 {
        font-size: 80px;
    }
}

.buttonsContainer {
    display: flex;
    gap: 12px;

    @include _1920x1080 {
        gap: 20px;
    }
}

.button {
    width: 205px;
    padding: 16px;
    border-radius: 15px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    @include _1920x1080 {
        font-size: 24px;
        line-height: 28px;
    }
}

.buttonFailed {
    background-color: #d75857;
    transition: 0.2s;

    &:hover {
        background-color: #b94e48;
    }

    &:active {
        background-color: #af4035;
    }
}

.buttonSuccess {
    background-color: #94d167;
    transition: 0.2s;

    &:hover {
        background-color: #70ba3c;
    }

    &:active {
        background-color: #5da130;
    }
}
