.root {
    width: 100%;
    height: 100%;
    position: relative;
    background: linear-gradient(0deg, #008281, #008281),
        radial-gradient(75.05% 75.05% at 50% 50%, #2e2e2e 0%, #0e0f10 67.85%);
    background: url('../../../public/img/NissanSkylineConceptBacground.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #ffffff;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/textures/OldDisplayLayer.jpg');
        background-repeat: repeat-y;
        background-position: center;
        background-size: cover;
        mix-blend-mode: overlay;
        opacity: 0.1;
        pointer-events: none;
    }
}

.TaskBar {
    position: absolute;
    bottom: 0;
}
