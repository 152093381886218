@import '../../styles/media.scss';

.root {
    padding: 70px;
    display: grid;
    grid-template-rows: repeat(7, 67px);
    grid-template-columns: repeat(18, 48px);
    gap: 20px;

    @include _1920x1080 {
        padding: 100px;
        grid-template-rows: repeat(7, 93px);
        grid-template-columns: repeat(18, 64px);
        gap: 40px;
    }
}
