.root {
    width: 854px;
    height: 431px;
    color: #000000;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu {
    margin: 0 6px;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin: 6px;
    padding: 24px;
    background-color: #ffffff;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
}
