@import '../../styles/media.scss';

.root {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #081300;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../assets/textures/OldDisplayLayer.jpg');
        background-repeat: repeat-y;
        background-size: 100% auto;
        mix-blend-mode: overlay;
        opacity: 0.1;
        pointer-events: none;
    }

    .taskBar {
        background-color: #7b7d77;
    }

    .startButton {
        background-color: #7b7d77;
    }
}

.errorContainer {
    flex-shrink: 1;
    padding: 70px;
    overflow-y: auto;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5bc65b;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    z-index: 2;

    @include _1920x1080 {
        padding: 100px;
        font-size: 24px;
        line-height: 36px;
    }
}

.keyWord {
    font-weight: 600;
    color: #ffffff;
}

.left {
    display: flex;
    gap: 8px;
}

.line {
    width: 2px;
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #808080;
}
