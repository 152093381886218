.root {
    width: 437px;
}

.buttonsWrapper {
    display: flex;
    justify-content: center;
    margin: 24px 0;
}

.content {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 21px;
    margin-bottom: 0;
}

.message {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}
