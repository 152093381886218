.root {
    min-width: auto;
    margin: 0px;
    padding: 5px 8px;
    display: flex;
    gap: 8px;
}

.icon {
    width: 23px;
    height: 21px;
}
