.root {
    width: 860px;
    height: 565px;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.windowContent {
    flex-grow: 1;
    margin: 3px;
    margin-top: 0px;
    margin-bottom: 6px;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
}
