.speechBalloon {
    top: 455px;
    left: 360px;
}

.text {
    position: absolute;
    bottom: 105px;
    right: 268px;

    width: 148px;
    height: 88px;

    font-family: 'BERNIER Distressed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 110%;
    color: #000000;
}
