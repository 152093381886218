.root {
    width: 517px;
    height: 127px;
    padding: 31px 50px 52px 50px;

    background-color: red;
    mask-image: url('../../../public/img/PinkButtonBackground.svg');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    text-align: center;

    font-family: 'BERNIER Distressed';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 110%;
    color: #ffffff;

    cursor: pointer;
    border: none;
}
