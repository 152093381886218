.root {
    display: flex;
    gap: 8px;
    padding: 5px 14px 3px 16px;
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #808080;
}

.icon {
    width: 24px;
    height: 24px;
}

.clock {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}
