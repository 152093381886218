.root {
    position: absolute;
    display: flex;
    flex-direction: column;
    // TEMP !!!
    z-index: 1;
}

.content {
    min-height: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
