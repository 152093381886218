.content {
    width: 411px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 24px 16px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.message {
    margin-bottom: 8px;
}

.seedPhraseInput {
    height: 42px;
    padding: 8px 12px;
    margin-bottom: 4px;
    background: #ffffff;
    box-shadow: inset -1px -1px 0px #dadada, inset 1px 1px 0px #808080;
}

.seedPhraseButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.longSeedPhraseButton {
    flex-grow: 1;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
