.root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background: linear-gradient(270deg, #1085d2 0%, #00007b 100%);
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
}

.iconWrapper {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    overflow: hidden;
}

.title {
    flex-grow: 1;
}

.closeButton {
    margin-left: 8px;
}
